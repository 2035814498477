<template>
  <div class="merlends">
    <el-form
      ref="queryParams"
      :model="queryParams"
      :inline="true"
      @keyup.enter.native="handleQuery"
    >
      <el-form-item prop="keyWord">
        <el-input
          maxlength="32"
          v-model="queryParams.keyWord"
          placeholder="协议名称/编号"
          clearable
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleQuery"> 查 询 </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="agreementData"
      :header-cell-style="{ background: '#F2F3F5' }"
      border
      v-loading="loading"
    >
      <el-table-column prop="id" label="协议编号" align="center" />
      <el-table-column prop="name" label="协议名称" align="center" />
      <el-table-column prop="signatory" label="签署对象" align="center">
        <template slot-scope="{ row }">
          <div>{{ row.signatory == 1 ? "供应商" : "客户" }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="status" align="center" label="状态">
        <template slot-scope="{ row }">
          <el-switch
            v-model="row.status"
            active-color="#13ce66"
            inactive-color="#cccccc"
            :active-value="1"
            :inactive-value="0"
            @change="changeStatus(row)"
          />
        </template>
      </el-table-column>
      <el-table-column prop="updateTime" label="最后修改时间" align="center">
        <template slot-scope="{ row }">
          <div>{{ row.updateTime || "-" }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="" label="操作" align="center">
        <template slot-scope="{ row }">
          <span class="btsed" @click="handleClick(row)">编辑</span>
          <span class="btsed" @click="record(row)">记录</span>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="queryParams.page"
      :limit.sync="queryParams.perPage"
      @pagination="getList"
    />
    <!-- 白条分佣设置弹窗 -->
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="850px"
    >
      <div style="height: 500px">
        <!-- <div class="richText scorr-roll">
          <quill-editor
            ref="myQuillEditor"
            v-model="content.text"
            :options="editorOption"
            @change="onEditorChange($event)"
          />
        </div> -->
        <pre><Editor v-model="content.text" class="Editor" /></pre>
      </div>
      <div slot="footer" class="text-right">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="conserve">保 存</el-button>
      </div>
    </el-dialog>
    <!-- 协议确认记录弹窗 -->
    <el-dialog
      title="协议确认记录"
      :visible.sync="dialogrecorts"
      :close-on-click-modal="false"
      width="850px"
    >
      <div class="hedst">
        <el-tabs v-model="ItemRecort.status" type="card" @tab-click="getRecort">
          <el-tab-pane label="已确认" name="1"></el-tab-pane>
          <el-tab-pane label="未确认" name="0"></el-tab-pane>
        </el-tabs>
        <div class="fx">
          <el-input
            v-model="ItemRecort.supplierName"
            placeholder="供应商名称"
            clearable
          />
          <el-button
            style="margin-left: 10px"
            type="primary"
            @click="getRecort"
          >
            查 询
          </el-button>
        </div>
      </div>
      <el-table
        :data="recortData"
        :header-cell-style="{ background: '#F2F3F5' }"
        border
        v-loading="loading"
        height="500px"
      >
        <el-table-column align="center" label="序号" type="index" width="70" />
        <el-table-column prop="supplierName" label="供应商" align="center">
          <template slot-scope="{ row }">
            <div>{{ row.supplierName || "-" }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" align="center">
          <template slot-scope="{ row }">
            <div>{{ row.status == 1 ? "已确认" : "待确认" }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="confirmor" label="确认人" align="center">
          <template slot-scope="{ row }">
            <div>{{ row.confirmor || "-" }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="confirmTime" label="确认时间" align="center">
          <template slot-scope="{ row }">
            <div>{{ row.confirmTime || "-" }}</div>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="text-right">
        <el-button @click="dialogrecorts = false">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Editor from "@/components/Editor/index.vue";
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("Basics"); //vuex公共库
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
import {
  changeStatus, //协议中心-协议启用状态切换
  record, //协议中心-协议确认记录
} from "@/api/apiAll/phpUrl.js";
export default {
  components: {
    Editor,
  },
  data() {
    return {
      loading: false,
      dialogVisible: false,
      title: "",

      queryParams: {
        page: 1,
        perPage: 10,
        keyWord: "",
      },
      total: 0,
      content: {
        id: "1",
        text: "",
      },
      agreementData: [],

      dialogrecorts: false, //协议确认记录弹窗
      ItemRecort: {
        id: "", //记录ID
        status: "1", //状态0待确认1已确认
        supplierName: "", //供应商
        userName: "", //客户
      },
      recortData: [], //确认记录
    };
  },
  computed: {
    ...commonIndex.mapState(["allJurisdiction"]),
  },
  created() {
    this.getList();
    // this.handleClick();
  },
  methods: {
    ...mapActions([
      "getagreementlist",
      "postagreementeditInfo",
      "getagreementeditInfo",
    ]),
    handleCurrentChange() {},
    async getList() {
      this.loading = true;
      let res = await this.getagreementlist(this.queryParams);
      this.loading = false;
      this.agreementData = res.data.data;
      this.total = res.data.total;
    },
    handleQuery() {
      this.getList();
    },
    // 弹窗
    async handleClick(row) {
      let data = await this.getagreementeditInfo({ id: row.id });
      data.data.id = data.data.id + "";
      this.content = data.data;
      this.title = row.name;
      this.content.id = row.id;
      this.dialogVisible = true;
    },
    // 保存
    async conserve() {
      let data = await this.postagreementeditInfo(this.content);
      if (data.code == 200) {
        this.$message({
          message: data.message,
          type: "success",
        });
        this.dialogVisible = false;
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    // 关闭
    toleave() {},
    // 富文本类容
    onEditorChange() {},
    /** 状态切换 */
    changeStatus(row) {
      changeStatus({ id: row.id }).then((res) => {
        if (res.code == 200) {
          this.$message.success("操作成功");
        }
        this.getList();
      });
    },
    /** 签署记录*/
    async record(row) {
      Object.assign(this.ItemRecort, this.$options.data().ItemRecort);
      this.ItemRecort.id = row.id;
      await this.getRecort();
      this.dialogrecorts = true;
    },
    /** 获取协议确认记录 */
    async getRecort() {
      this.loading = true;
      let res = await record(this.ItemRecort);
      this.loading = false;
      this.recortData = res.data;
    },
  },
};
</script>
<style lang="scss" scoped>
.editor {
  line-height: normal !important;
  //   height: 500px;
}
.ql-snow .ql-tooltip[data-mode="link"]::before {
  content: "请输入链接地址:";
}
.ql-snow .ql-tooltip.ql-editing {
  left: 100px !important;
}
.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  border-right: 0px;
  content: "保存";
  padding-right: 0px;
}

.ql-snow .ql-tooltip[data-mode="video"]::before {
  content: "请输入视频地址:";
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: "14px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
  content: "10px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
  content: "18px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
  content: "32px";
}

.ql-snow .ql-picker.ql-header .ql-picker-label::before,
.ql-snow .ql-picker.ql-header .ql-picker-item::before {
  content: "文本";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  content: "标题1";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  content: "标题2";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  content: "标题3";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  content: "标题4";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
  content: "标题5";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
  content: "标题6";
}

.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: "标准字体";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
  content: "衬线字体";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
  content: "等宽字体";
}
</style>
<style lang="scss" scoped>
.MERCH {
  width: 100%;
  height: 100%;
  .merch-card {
    width: 100%;
    height: 100%;
    min-height: 600px;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    background: #ffffff;
    .merch-tag {
      width: 100%;
      height: 40px;
    }
    .richText {
      margin-top: 30px;
      max-width: 800px;
      max-height: 85%;
      min-height: 50vh;
    }
    .compile-BTN {
      margin-top: 30px;
      width: 100%;
      display: flex;
      justify-content: center;
      .cancel-btn {
        width: 100px;
        height: 40px;
        line-height: 40px;
        background: #f5f6f7;
        color: #c6c7c9;
        text-align: center;
        border-radius: 5px;
        cursor: pointer;
      }
      .save-btn {
        margin-left: 20px;
        background: #06b7ae;
        color: #fff;
      }
    }
  }
}
.texts {
  display: flex;
  justify-content: center;
  max-height: 55vh;
}

.hedst {
  display: flex;
  justify-content: space-between;
  align-items: center; // 垂直居中对齐
}

::v-deep .el-tabs__item {
  width: 10.66vw;
  text-align: center;
}
</style>
